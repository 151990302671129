/**
 * ╒═══════════════════════════════════════╕
 * │           HoW tO wrItE CoDE           │
 * ╞═══════════════════╤═══════════════════╡
 * │          variable │ --like-this       │
 * │ cust. media query │ --[for|when]-[..] │
 * ├───────────────────┼───────────────────┤
 * │      layout, obj. │ .o-[..]           │
 * │    element, cmpt. │ .like-this        │
 * │         CSS state │ .[is|has]-[..]    │
 * │          JS state │ .js-[..]          │
 * │       theme, mod. │ .-like-this       │
 * └───────────────────┴───────────────────┘
 */

/* --- Styles --- */
@import '../../variables.css';

@import '../styles-async/article.css';
@import '../styles-async/breadcrumbs.css';
@import '../styles-async/form.css';

@import '../styles-async/footer.css';
@import '../styles-async/links.css';

@import '../styles-components/cookie-banner.css';
@import '../styles-components/sectionNews.css';
@import '../styles-components/sectionSegmentControls.css';
@import '../styles-components/sectionTeaserImage.css';
@import '../styles-components/benefitsList.css';
@import '../styles-components/linkList.css';
@import '../styles-components/table.css';
@import '../styles-components/tabs.css';
@import '../styles-components/map.css';
@import '../styles-components/serviceImages.css';
@import '../styles-components/snap-slider.css';
@import '../styles-components/snap-teasers.css';
@import '../styles-components/usps.css';
@import '../styles-components/imageHoverTable.css';
@import '../styles-components/job-overview.css';
@import '../styles-components/toggle-list.css';
@import '../styles-components/testimonials.css';
@import '../styles-components/contact.css';
@import '../styles-components/cta-bullet.css';
