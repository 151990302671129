.bsb-quicklinks-career button[role="button"]:not([aria-expanded="true"]).toggle-link::after {
  content: "\e628";
}
.snap-teasers-inner.js-expanded {
  margin: 0!important
}

.snap-teasers-stripe {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.snap-teasers-stripe::-webkit-scrollbar {
  display: none;
}

.quicklink-career {
  display: block;
}

.quicklink-career .box {
  padding: 35px 32px 28px;
}

@media (min-device-width: 1025px) {
  a.quicklink-career:hover .link {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.quicklink-career .link:not([href^="tel"]):not([href^="phone"])::after {
  margin-top: 2px;
  margin-left: 6px;
}

.quicklink-career .big3 + .h4 {
  margin-top: 39px;
}

snap-teasers > .quicklink-career {
  visibility: hidden;
}

.snap-teasers-stripe > .quicklink-career > .box {
  height: 100%;
}

.snap-teasers-stripe.js-visible > .quicklink-career {
  visibility: visible;
}

.snap-teasers-stripe.js-expanded .quicklink-career-placeholder {
  display: none;
}

/* Stripe View */
.bsb-quicklinks-career-grid,
.snap-teasers-stripe.js-expanded {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 40px;
}

@media (max-width: 1024px) {
  .snap-teasers-stripe.js-expanded {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .snap-teasers-stripe.js-expanded {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }


  .quicklink-career .box {
    padding: 27px 30px 35px;
  }

  .quicklink-career .big3 {
    font-size: 72px;
  }

  .quicklink-career .big3 + .h4 {
    margin-top: 27px;
  }
}


/* Grid View */
.snap-teasers-stripe:not(.js-expanded) {
  position: relative;
  scroll-snap-type: x proximity;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
}

.snap-teasers-stripe:not(.js-expanded) > .quicklink-career {
  scroll-snap-align: start;
  padding-left: 16px;
}

.snap-teasers-stripe:not(.js-expanded) > .quicklink-career:last-child {
  padding-right: 16px;
}

.snap-teasers-stripe:not(.js-expanded) > .quicklink-career > .box {
  width: 404px;
  max-width: calc(100vw - 48px);
}

@media (min-width: 768px) {
  .snap-teasers-stripe:not(.js-expanded) {
    min-height: 275px;
  }

  .quicklink-career > .box {
    min-height: 275px;
  }
}

@media (max-width: 767px) {
  .snap-teasers-stripe:not(.js-expanded) {
    min-height: 220px;
  }

  .quicklink-career > .box {
    min-height: 220px;
  }
}

@media (max-width: 450px) {
  .snap-teasers-stripe:not(.js-expanded) > .quicklink-career > .box {
    max-width: 282px;
  }

  .snap-teasers-stripe:not(.js-expanded) > .quicklink-career {
    padding-left: 24px;
  }
}
