main > .o-section.bsb-section-jobs:first-child {
  margin-top: 68px;
}

.bsb-section-jobs .tabs {
  padding-top: 16px;
}

.job-slider-wrapper {
  position: relative;
  height: 45px;
  width: 600px;
  max-width: 100%;
}

.job-slider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: #eee;
}

.job-range {
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #000;
}

.job-cursor {
  position: absolute;
  left: 0;
  cursor: pointer;
}

.job-cursor.job-cursor-right {
  left: 100%;
}

.job-cursor:before {
  content: "";
  position: absolute;
  top: -25px;
  left: -25px;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.job-cursor:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: black;
}

.job-counter {
  position: absolute;
  top: 100%;
  min-width: 38px;
  font-size: 16px;
}

.job-counter-start {
  left: 0;
  right: auto;
}

.job-counter-stop {
  left: auto;
  right: 0;
}

.job-teaser {
  border: 1px solid #d2d2d2;
}

.job-slider-wrapper {
  margin-bottom: 96px;
  margin-top: -4px;
}

.job-results .h4 {
  margin-bottom: 36px;
}

.job-teaser {
  min-height: 323px;
  hyphens: auto;
}

.job-teaser .h4 {
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .job-cursor-stop:after {
    left: -14px;
  }

  .job-teaser {
    min-height: 302px;
  }
}

.job-jobId {
  position: absolute;
  font-size: 11px;
  color: var(--grau1);
  padding-bottom: 30px;
  transform: translateY(-100%);
}

@media (max-width: 768px) {
  .job-jobId {
    padding-bottom: 40px;
  }
}
