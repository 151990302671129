article hr {
    border: 0;
    border-bottom: 1px solid black;
    margin: 1.75em 10rem;
}

article ol,
article ul {
    list-style: disc;
    padding-inline-start: 20px;
}

article li {
    margin-bottom: 16px;
    font-weight: normal;
}

article td ol,
article td ul {
    list-style: disc;
    padding-inline-start: 20px;
}

article td li {
    margin-bottom: 0.5em;
}

@media (max-width: 992px) {
    .-home article {
        margin-top: 0;
    }

    article li {
        margin-bottom: 24px;
    }
}

.o-editorial + .o-editorial:not([class*="cell-"]),
.o-lazy-media + .o-editorial:not([class*="cell-"]),
.o-google-map + .o-editorial:not([class*="cell-"]) {
    margin-top: var(--section-margin-height);
}

.o-editorial + .o-lazy-media:not([class*="cell-"]),
.o-editorial + .o-google-map:not([class*="cell-"]) {
    margin-top: var(--section-margin-height);
}

.o-lazy-media {
    position: relative;
}


/* --- Lazy Media Editorial --- */
.o-lazy-media .o-editorial {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
}

.o-lazy-media .o-editorial .title {
    color: inherit;
}

/* --- Search --- */
.o-search {
    list-style: none;
}

.o-search .search-result:not(:last-child) {
    margin-bottom: var(--section-margin-height)
}
