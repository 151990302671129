.cta-bullet {
	position: relative;
	font-size: calc(150px + (250 - 150) * (100vw - 768px) / (1460 - 768));
	padding-bottom: 0.5em;
}

@media (max-width: 768px) {
	.cta-bullet {
		font-size: 150px;
	}
}

@media (min-width: 1461px) {
	.cta-bullet {
		font-size: 250px;
	}
}

[class*="cta-bullet-outer"] {
	position: absolute;
	padding: 0;
	bottom: 0;
	text-align: center;
	color: var(--weiss);
	transform: rotate(-10deg);
	font-size: inherit;
}

[class*="cta-bullet-outer"] .cta-bullet-inner {
	padding: 0.062em;
	position: relative;
	width: 1em;
	height: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
}

[class*="cta-bullet-outer"] .cta-bullet-bubble {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 0.5em;
	transition: transform var(--default-timing);
}

[class*="cta-bullet-outer"] h3 {
	display: block;
	position: relative;
	color: var(--weiss);
	font-size: 0.124em;
	line-height: 1.29em;
	margin-top: -0.15em;
}

@media (min-device-width: 1025px) {
	a[class*="cta-bullet-outer"]:hover .cta-bullet-bubble {
		transform: scale(1.1);
		transition: transform var(--default-timing);
	}
}

@media (min-width: 768px) {
	[class*="cta-bullet-outer"] {
		right: 0.44em;
	}
}

@media (max-width: 767px) {
	[class*="cta-bullet-outer"] {
		right: 0.1333em;
	}
}

.cta-bullet-outer--red .cta-bullet-bubble {
	background-color: var(--rot);
}

.cta-bullet-outer--green .cta-bullet-bubble {
	background-color: var(--gruen);
}

.cta-bullet-outer--blue .cta-bullet-bubble {
	background-color: var(--blau);
}
