.bsb-linkList .link-row:not(:last-child) {
    margin-bottom: 10px;
}

.bsb-linkList-target .cell-1of1-sm-down {
    margin-bottom: 28px;
}

.bsb-linkList-target .link {
    letter-spacing: 0;
}

.bsb-linkList-target .link:after {
    font-size: 1em;
    margin-left: 0.65em;
    margin-top: 0.16em;
}

@media (max-width: 1440px) {
    .bsb-linkList-target .link.fs-link-medium {
        font-size: calc(27px + (31 - 27) * (100vw - 1180px) / (1440 - 1180));
    }
}

@media (max-width: 1180px) {
    .bsb-linkList-target .link.fs-link-medium {
        font-size: calc(16px + (27 - 16) * (100vw - 768px) / (1180 - 768));
    }
}

@media (max-width: 820px) {
    .bsb-linkList-target .cell-1of1-sm-down {
        margin-bottom: 31px;
    }

    .bsb-linkList-target .link.fs-link-medium {
        font-size: 21px;
        width: calc(100% + 22px);
        letter-spacing: 0.084px;
    }

    .bsb-linkList-target .link:after {
        font-size: 16px;
        margin-left: 15px;
        margin-top: 1px;
    }
}

@media (max-width: 768px) {
    .bsb-linkList .link-row:not(:last-child) {
        margin-bottom: 5px;
    }
}
