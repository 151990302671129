:not(.show-only-three-mobile) button[role="button"].toggle-link {
    display: flex;
}

.show-only-three-mobile button[role="button"].toggle-link {
    display: none;
}

button[role="button"].toggle-link::after {
    content: "\e62a";
    margin-top: 2px;
    font-weight: initial;
}

button[aria-expanded="true"].toggle-link::after {
    transform: rotate(180deg);
}

.show-only-three:not(.show-only-three-mobile) .list-item:nth-child(n+4){
    display: none;
}

@media (max-width: 768px) {
    .show-only-three-mobile .list-item:nth-child(n+4){
        display: none;
    }

    .show-only-three-mobile button[role="button"].toggle-link {
        display: flex;
    }
}