@import url(../../variables.css);

.banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    font-size: 16px;
    line-height: 24px;
    transform: translateY(200%);
    visibility: hidden;
    transition: transform 0.5s linear 0s, visibility 0s linear 1s;
}

.banner.js-visible {
    visibility: visible;
    transform: translateY(0);
    transition: transform 0.5s linear 0.15s, visibility 0s linear 0s;
}

@media (min-width: 993px) {
    .banner {
        bottom: var(--element-spacing);
    }
}

.banner .o-group {
    max-width: 1116px;
}

.banner-box {
    padding: 20px 23px;
    border: 1px solid #EEF0F0;
    box-shadow: 0 16px 20px rgba(16, 25, 31, 0.15);
}

@media (max-width: 992px) {
    .banner-box {
        padding: 21px 24px 40px;
    }
}

.banner-cell-buttons {
    justify-content: center;
    text-align: center;
    object-position: center;
}

.banner-button-wrapper > button {
    padding: 12px 20px;
    letter-spacing: 0.28125px;
}

@media (min-width: 1200px) {
    .banner-cell-buttons {
        justify-content: flex-end;
        text-align: right;
        object-position: right;
    }
}

@media (min-width: 451px) {
    .banner-button-wrapper {
        margin: 0 -9px;
    }

    .banner-button-wrapper > button {
        margin: 0 12px 0 8px;
    }
}

@media (max-width: 450px) {
    .banner-button-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: -20px;
        padding-bottom: 5px;
        margin-bottom: -10px;
        justify-content: center;
    }

    .banner-button-wrapper > button {
        margin-bottom: 10px;
    }

    .banner [class*="o-flex"].has-small-gap-sm-down > * {
        margin-bottom: 19px;
    }
}
