form {
    position: relative;
}

/* --- Default settings --- */
fieldset {
    position: relative;
    display: inline-block;
    min-width: 100%;
    margin-bottom: 3rem;
}

@media (max-width: 767px) {
    fieldset {
        margin-bottom: 2rem;
    }
}

fieldset:last-child {
    margin-bottom: 0;
}

.required-legend {
    font-size: 15px;
}

.winnie {
    display: block;
    position: absolute;
    overflow: hidden;
    right: -2466px;
}

.winnie label {
    position: static;
}

fieldset .form-item {
    display: inline-block;
    margin-right: 2em;
    vertical-align: top;
}

button, input, textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
}

button:-moz-ui-invalid,
input:-moz-ui-invalid,
textarea:-moz-ui-invalid {
    box-shadow: 0 0;
}

button, input, textarea, label {
    transition: 350ms;
}

input, textarea {
    border-radius: 0;
    font-family: inherit;
    font-size: inherit;
}

form button,
label:not(.is-static),
input[type="radio"],
input[type="checkbox"],
input[type="submit"],
input[type="button"] {
    cursor: pointer;
}

form button,
form button.has-svg-icon,
input[type="submit"],
input[type="button"] {
    padding: 0.75em 1em 0.85em 1em;
    border-radius: 4px;
    border: 1px solid var(--button-border-color);
    line-height: 1.25em;
    background: var(--button-default-background);
    color: #fff;
}

form button.has-svg-icon {
    padding: 0.5em;
    min-height: 2.25em; /* line-height + padding */
    min-width: 2.25em;
}

form button.has-svg-icon > svg {
    max-height: 1em;
}

form button:focus,
input[type="submit"]:focus,
input[type="button"]:focus {
    outline: 0;
    background: var(--button-active-background);
    box-shadow: 0 0 0 1px var(--page-background), 0 0 0 4px hsla(var(--form-active-color), 0.35);
}

@media (min-device-width: 1025px) {
    form button:hover,
    input[type="submit"]:hover,
    input[type="button"]:hover {
        background: var(--button-active-background);
    }
}

@media (max-device-width: 1024px) {
    form button:active,
    input[type="submit"]:active,
    input[type="button"]:active {
        background: var(--button-active-background);
    }
}

[type="number"] { -moz-appearance: textfield; }
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

label,
legend {
    display: inline-block;
    line-height: 1.15em;
    margin-bottom: 1.15em;
}

select, option {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    border: 0;
    border-radius: 0;
    appearance: none;
}

select option {
    padding: 0.75em 2em 0.85em 1em;
    line-height: 1.25em;
    height: auto!important;
    min-height: 3em;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]),
textarea, select {
    width: 100%;
    padding: 0.75em 2em 0.85em 1em; /* padding-right 2em because of ie */
    background: transparent;
    border-radius: 4px;
    border: 1px solid var(--form-border-color);
    line-height: 1.25em;
    box-shadow: 0 0 0 1000px var(--form-input-background) inset!important;
}

input[type="search"] {
    width: 100%;
    padding: 0.5em;
    background: transparent;
    border-radius: 4px;
    border: 1px solid var(--form-border-color);
    line-height: 1.25em;
    box-shadow: 0 0 0 1000px var(--form-input-background) inset!important;
}


form input:-internal-autofill-previewed,
form input:-internal-autofill-selected,
form textarea:-internal-autofill-previewed,
form textarea:-internal-autofill-selected,
form select:-internal-autofill-previewed,
form select:-internal-autofill-selected {
    background: transparent!important;
}


@media (max-width: 767px) {
    select,
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]),
    input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]),
    textarea {
        padding: 0.5em 1em 0.6em;
        line-height: 1.4em;
    }
}

textarea {
    resize: vertical;
    padding: 1em;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]):focus,
textarea:focus,
select:focus {
    outline: 0;
    border-color: hsl(var(--form-active-color));
    background: hsl(var(--form-active-color));
    box-shadow: 0 0 4px hsla(var(--form-active-color), 0.7), 0 0 0 1000px white inset!important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]).js-error,
textarea.js-error {
    outline: 0!important;
    border-color: hsl(var(--form-error-border-color));
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]).js-error:focus,
textarea.js-error:focus {
     box-shadow: 0 0 4px hsla(var(--form-error-border-color), 0.7), 0 0 0 1000px white inset!important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]).js-valid,
textarea.js-valid {
    outline: 0!important;
    border-color: hsl(var(--form-valid-border-color));
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]).js-valid:focus,
textarea.js-valid:focus {
     box-shadow: 0 0 4px hsla(var(--form-valid-border-color), 0.7), 0 0 0 1000px white inset!important;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]).js-error:focus,
textarea.js-error:focus {
    outline: 0 0 4px hsla(var(--form-active-color), 0.7)!important;
}

input:disabled,
textarea:disabled {
    opacity: 0.35;
}

input:not([type="submit"]):read-only {
    background-color: var(--form-readonly-color) !important;
}

/* Checkbox + Radio */
input[type="checkbox"],
input[type="radio"] {
    cursor: pointer;
    padding: 8px;
    background: white;
    border: 2px solid hsl(var(--form-default-color));
    box-shadow: inset 0 0 0 2px white;
    vertical-align: middle;
    outline: 0;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
    border-color: hsl(var(--form-active-color))
}

input[type="radio"] {
    border-radius: 11px;
}

input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    /*visibility: hidden;*/
    padding: 0;
    width: 0;
    height: 0;
    border: 0;
    opacity: 0;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
    background: hsl(var(--form-default-color));
    box-shadow: inset 0 0 0 2px white;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
    position: relative;
    transform: unset;
    line-height: 1.15em;
    min-height: 2em;
    margin-bottom: 0;
    padding: 0.25em 0 0 2em;
    font-size: 16px;
    width: 100%;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    border: 1px solid var(--form-border-color);
    background-color: var(--form-input-background);
}

input[type="checkbox"].js-error + label::before,
input[type="radio"].js-error + label::before {
    border-color:  hsl(var(--form-error-border-color));
}

input[type="checkbox"]:checked.js-valid + label::before,
input[type="radio"]:checked.js-valid + label::before {
    border-color:  hsl(var(--form-valid-border-color));
}

input[type="checkbox"] + label::before {
    border-radius: 3px;
}

input[type="checkbox"]:checked + label::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 1.5em;
    height: 1.5em;
    content: "\2714";
    color: currentColor;
    text-align: center;
    line-height: 1.4em;
}

input[type="checkbox"]:disabled + label::before,
input[type="radio"]:disabled + label::before {
    border: 1px solid var(--form-readonly-color);
}

input[type="checkbox"]:checked:disabled + label::after,
input[type="radio"]:checked:disabled + label::after {
    color: var(--form-readonly-color);
}

input[type="radio"] + label::before {
    border-radius: 50%;
}

input[type="radio"]:checked + label::after {
    content: "";
    position: absolute;
    width: 0.5em;
    height: 0.5em;
    top: 0.5em;
    left: 0.5em;
    border-radius: 50%;
    background-color: currentColor;
}

/* --- Label --- */

label:not(.is-static) {
    margin-bottom: 1.15em;
}

label.js-error:not(.is-static) {
    color: var(--form-error-text-color);
}

label.as-placeholder {
    position: absolute;
    transform: translate3d(0, 0, 0);
    transform-origin: left bottom;
    opacity: 0.85;
    will-change: opacity, transform;
    background-color: transparent!important;
    box-shadow: none!important;
    border: none!important;
    transition: transform 150ms ease-in-out, opacity 150ms linear;
}

label.as-placeholder.active {
    color: hsl(var(--form-active-color));
    transform: translate3d(-0.5em, -2.5em, 0) scale(0.7);
    opacity: 1;
}

label span {
    white-space: nowrap;
}

input:invalid + label.active {
    color: hsl(var(--form-error-border-color));
}

.form-field {
    position: relative;
    line-height: 0;
}

[class^="field-error"] {
    font-size: 10px;
    line-height: 1.15em;
    white-space: normal;
    color: var(--form-error-text-color);
    display: inline-block;
}

[class^="field-error"] > p {
    margin: 0 0 0.5em 0;
    font-size: inherit;
    line-height: inherit;
}

[class^="field-error"]:not(.field-error-inline) {
    padding-top: 10px;
}

.form-message {
}

.form-message-error {
    color: var(--form-error-text-color);
}

.form-message-sending {
}

.form-message-success {
}

form .button-wrapper {
    text-align: right;
}

@media (max-width: 767px) {
    form .button-wrapper {
        text-align: left;
    }
}

form button,
form .button-wrapper .button,
form input[type="submit"] {
    margin: 0 0.5em;
    padding: 0.5em;
    border-radius: 5px;
    border: 1px solid var(--form-border-color);
}

form .cell-1of1,
form .cell-1of2,
form .language {
    position: relative;
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="search"]),
textarea {
    color: inherit;
}

/* Placeholder style */

::-webkit-input-placeholder {
    color: var(--grau);
}

/* Firefox 4-18 */
:-moz-placeholder {
    color: var(--grau);
}

/* Firefox 19-50 */
::-moz-placeholder {
    color: var(--grau);
}

/* - Internet Explorer 10–11
   - Internet Explorer Mobile 10-11 */
:-ms-input-placeholder {
    color: var(--grau)!important;
}

/* Edge (also supports ::-webkit-input-placeholder) */
::-ms-input-placeholder {
    color: var(--grau);
}

/* CSS Pseudo-Elements Level 4 Editor's Draft
   - Browsers not mentioned in vendor prefixes
   - Browser of newer versions than mentioned in vendor prefixes */
::placeholder {
    color: var(--grau);
}
