.o-contact-managers h4,
.o-contact h4 {
    margin-bottom: 4px;
}

.o-contact-managers h4:not(:first-child) {
    padding-top: 0;
    margin-top: var(--block-spacing);
}

.o-contact-managers .prose {
    margin-bottom: 43px;
}

.o-contact header {
    padding-bottom: 3px;
}

.o-contact .contact-phone {
    margin-top: -2px;
}

.o-contact .contact-email {
    padding-top: 1px;
}

.o-contact .contact-info {
    line-height: 22px;
}

@media (max-width: 768px) {
    .o-contact-managers h4 {
        font-size: 23px;
    }

    .o-contact-managers img {
        max-height: 198px;
    }

    .o-contact-managers .big-arrow {
        padding-top: 4px;
    }

    .o-contact-managers .prose {
        margin-bottom: 16px;
    }

    .o-contact header.has-hand {
        padding-bottom: 18px;
    }
}

.contact-hand {
    margin-left: 0.335em;
    max-width: 100px;
    max-height: 100px;
    vertical-align: middle;
    margin-top: -18px;
}

@media (max-width: 768px) {
    .contact-hand {
        max-width: 71px;
        max-height: 71px;
        margin-left: 0.25em;
    }
}
