.bsb-serviceImage {
  position: relative;
  padding-top: 555px;
  width: 100%;
}

.bsb-serviceImage > picture {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: opacity 0.25s ease-out 0s;
}

.bsb-serviceImage:hover > picture {
  opacity: 0.75;
}

.bsb-serviceImage > picture > img {
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.25s ease-out 0s;
}

.bsb-serviceImage:hover > picture > img {
  opacity: 0.75;
  transform: scale(1.1);
}

.bsb-serviceImage-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 20px;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.25s ease-out 0s;
  max-width: 315px;
  margin: 0 auto;
}

.bsb-serviceImage:hover .bsb-serviceImage-overlay {
  opacity: 1;
}

@media (min-width: 768px) {
  .bsb-serviceImage {
    margin: -24px 0;
    clip-path: inset(25px 0% 25px 0%);
    transition: clip-path 0.25s ease-out 0s;
  }

  .bsb-serviceImage:hover {
    clip-path: inset(0% 0% 0% 0%);
  }
}
