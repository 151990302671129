:root {
    --breadcrumb-space: 7px;
}

.o-breadcrumbs {
    margin-bottom: 12px;
    font-size: 14px;
}

.o-breadcrumbs ol {
    list-style-type: none;
}

.o-breadcrumbs li.splitter {
    margin: 0 var(--breadcrumb-space);
}

.o-breadcrumbs li:last-child {
    display: none;
}

.o-breadcrumbs .link {
    transition: opacity var(--default-timing);
    text-decoration: none;
    outline: 0;
    position: relative;
}

.o-breadcrumbs .has-svg-icon {
    line-height: inherit;
}

@media (max-width: 992px) {
    .o-breadcrumbs li:not(.show-on-mobile) {
        display: none;
    }
}
