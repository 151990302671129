.o-google-map {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.o-google-map.js-initialized {
  padding-top: 56.25%;
  opacity: 1;
  transition: opacity 0.25s linear 0.25s;
}

.o-google-map.js-loaded {
  opacity: 1;
}

.o-google-map > .map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bsb-map .bsb-direction-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bsb-map .gm-style-iw-d div {
  font-family: "Matter" !important;
  /* font-size: 18px;
  line-height: 24px; */
  min-width: 100px;
  text-align: center;
}

.bsb-map .gm-style-iw-d  h5 + p {
  margin-top: 14px;
  margin-bottom: 8px;
  font-size: var(--default-font-size);
  line-height: 26px;
}

.gm-style .gm-style-iw-c {
  width: 324px !important
}

.bsb-map .gm-style-iw-d > div {
  padding: 6px 12px;
}

.bsb-map .bsb-direction {
  background-color: #459ace;
  height: 35px;
  width: 35px;
  cursor: pointer;
  text-align: center;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  min-width: 0 !important;
  margin-bottom: 2px;
}

.bsb-map .direction-icon {
  padding: 0 !important;
  min-width: 0 !important;
}

.bsb-map .direction-icon::after {
  content: "\e6bc";
  display: inline-block;
  font-family: themify;
  vertical-align: middle;
  font-size: 16px;
  line-height: 35px;
  margin-top: -2px;
  color: white;
}