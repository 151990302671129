#pageFooter {
    padding-top: 29px;
    padding-bottom: 0;
    line-height: 34px;
}

#pageFooter a {
    width: fit-content;
    display: block;
    margin-bottom: 6px;
    margin-top: 2px;
}

#pageFooter .cell-1of1-sm-down {
    margin-bottom: 32px;
}

#pageFooter a.link-social,
#pageFooter a.link-image {
    display: inline-block;
}

#pageFooter a.link-image {
    margin-top: -4px;
}

#pageFooter a.link-image img {
    max-width: 118px;
}

#pageFooter .mb-element:last-child {
    margin-bottom: var(--element-spacing);
}

.link-social + .link {
    margin-top: 26px !important;
}

.link-social.no-icon::after {
    content: "";
    display: block !important;
    width: 22px;
    height: 22px;
    transform: none;
    margin-top: 5px;
    margin-left: 0;
    margin-right: 11px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.link-facebook::after {
    background-image: url("../../assets/icons/Facebook.svg");
}

.link-instagram::after {
    background-image: url("../../assets/icons/Instagram.svg");
}

.link-linkedin::after {
    background-image: url("../../assets/icons/LinkedIn.svg");
}

@media (max-width: 991px) {
    #pageFooter {
        padding-top: 33px;
        line-height: 36px;
    }

    #pageFooter a {
        margin-top: 8px;
    }

    .link-social.no-icon::after {
        margin-top: -2px;
    }
}

@media (max-width: 768px) {
    #pageFooter {
        padding-bottom: 18px;
    }
}
