@media (min-width: 768px) {
  .bsb-table td.last-nowrap:last-child {
    white-space: nowrap;
    text-align: right;
    padding-right: 0;
    width: auto!important;
  }

  .bsb-table td:last-child a {
    margin-left: -10px;
    margin-right: -6px;
  }
}

.bsb-table tr {
  border-top: 1px solid var(--grau2)
}

.bsb-table td {
  padding: 9px 10px 10px 0;
}

.bsb-table tr:last-child {
  border-bottom: 1px solid var(--grau2)
}

.bsb-table .link[download],
.bsb-table .link[href*="dam"] {
  flex-flow: row-reverse;
}

.bsb-table .link[download]::after,
.bsb-table .link[href*="dam"]::after {
  content: url("../../assets/icons/document.svg");
  margin-left: 0;
  margin-right: 16px;
}

@media (max-width: 768px) {
  .bsb-table tr {
    display: block;
    padding: 6px 0;
  }
  .bsb-table td {
    display: block;
    min-width: 100%;
    padding: 3px 0;
  }

  .bsb-table .link[download]::after,
  .bsb-table .link[href*="dam"]::after {
    margin-right: 1em;
  }
}
