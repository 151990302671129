button[role="button"],
.link,
.has-link a {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-decoration: none;
  line-height: 1em;
  padding: 5px 0.5em 5px 0.6em;
  border-radius: 0.33em;
  margin-left: -0.6em;
  background-color: rgba(0,0,0,0);
  transition: background-color 0.15s linear;
  margin-top: -5px;
  margin-bottom: -5px;
}

button,
.link,
button:hover,
.link:hover,
button:active,
.link:active {
  color: inherit;
}

.button {
  background-color: black;
  color: white;
  padding: 12px 18px;
  border-radius: 10px;
  transition: color 0.15s linear, background-color 0.15s linear;
}

.button.secondary {
  color: black;
  background-color: transparent;
}

.button:hover {
  background-color: var(--blau);
}

.button.secondary:hover {
  color: var(--blau);
  background-color: transparent;
}

.link.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

p > a:not(.link) {
  padding: 5px 0.25em;
  margin: -5px -0.2em;
}

.prose [href^="http"]::after,
button[role="button"]::after,
.link::after,
.has-link a::after{
  content: "";
  display: inline-block;
  font-family: 'themify', sans-serif;
  vertical-align: middle;
  font-size: 0.7em;
  font-weight: 600;
}

.link::after {
  content: "\e628";
  font-size: 0.655em;
  margin-top: 0.15em;
  margin-left: 1.05em;
}

.prose [href^="http"]::after,
.link[target="_blank"]::after,
.has-link a[target="_blank"]::after {
  content: "\e628";
  font-size: 0.655em;
  margin-top: 0.15em;
  margin-left: 1.05em;
  transform: rotateZ(-45deg);
}

.prose [href^="http"]::after,
.has-link a[target="_blank"]::after {
  margin-top: 0;
}

.link[download]::after,
.link[href*="dam"]::after {
  content: "\e6a7";
  margin-top: 0.05em;
  margin-left: 1.05em;
}

button[aria-expanded="false"]::after {
  content: "\e64b";
  margin-left: 1em;
  margin-top: -0.1em;
}

button[aria-expanded="true"]::after {
  content: "\e648";
  margin-left: 1em;
  margin-top: -0.1em;
}

.big-arrow button[aria-expanded="false"]::after {
  content: "\e62a";
  margin-left: 1em;
  margin-top: 2px;
}

.big-arrow button[aria-expanded="true"]::after {
  content: "\e627";
  margin-left: 1em;
  margin-top: 2px;
}

.link.fs-link-medium {
  font-size: 18px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .link.fs-link-medium {
    font-size: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1344px) {
  .link.fs-link-medium {
    font-size: 28px;
  }
}

@media (min-width: 1345px) {
  .link.fs-link-medium {
    font-size: 31px;
  }
}

.link:not([href^="tel"]):not([href^="phone"])::after {
  float: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  text-decoration: none;
}

a[href^="mailto:"] {
  word-break: break-all;
}

@media (hover: hover) and (pointer: fine) {
  button[role="button"]:hover,
  a.link:not(.no-hover):hover,
  .has-link a:hover{
    background-color: rgba(0, 0, 0, 0.05)
  }
}

a.is-active {
  background-color: rgba(0,0,0,0.05)
}

/* No icon */
a.big2 {
  transition: color 0.15s linear;
}

a.big2:hover {
  color: var(--grau1);
}

a.no-icon::after {
  display: none!important;
}
