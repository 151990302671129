@import url(../../variables.css);

.imageHoverTable .image-area {
  position: relative;
  margin: 67px 0;
}
.imageHoverTable .image-area .hover-area {
  position: absolute;
  padding: 0;
  display: inline-block;
  margin: 0;
  transform: none;
  border-radius: 0;
  transition: all ease 0.3s;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(126, 186, 215, 0);
}
.imageHoverTable .image-area .hover-area:after {
  content: "";
  display: none;
}
.imageHoverTable .image-area .hover-area.hover,
.imageHoverTable .image-area .hover-area:hover {
  background-color: rgba(126, 186, 215, 0.5);
}
.imageHoverTable .image-area .hover-area-1 {
  clip-path: polygon(10.8% 2.8%, 10.8% 30.6%, 15.6% 30.6%, 15.6% 2.8%);
}
.imageHoverTable .image-area .hover-area-2 {
  clip-path: polygon(69.2% 1.8%, 73.4% 1.8%, 73.4% 30.6%, 65.6% 30.6%, 65.6% 20%, 69.2% 20%);
}
.imageHoverTable .image-area .hover-area-3 {
  clip-path: polygon(44.8% 1%, 49.6% 1%, 49.6% 30.6%, 40.2% 30.6%, 40.2% 20%, 44.8% 20%);
}
.imageHoverTable .image-area .hover-area-4-1 {
  clip-path: polygon(1.1% 43.9%, 10% 43.9%, 10% 86%, 7.5% 83%, 7% 90%, 4% 90%, 3.4% 83%, 1.1% 86%);
}
.imageHoverTable .image-area .hover-area-4-2 {
  clip-path: polygon(58.8% 43.9%, 72.2% 43.9%, 72.2% 90%, 70.9% 90%, 70.3% 83%, 67.8% 86%, 65.2% 83%, 64.7% 90%, 61.8% 90%, 61.1% 83%, 58.8% 86%);
}
.imageHoverTable .image-area .hover-area-5 {
  clip-path: polygon(19% 43.9%, 49.6% 43.9%, 49.6% 86%, 47% 83%, 44.7% 86%, 41.8% 83%, 39.5% 86%, 37% 83%, 34.3% 86%, 31.7% 83%, 29% 86%, 26.8% 83%, 24.2% 86%, 21.6% 83%, 19% 86%);
}
.imageHoverTable .image-area .hover-area-6 {
  clip-path: polygon(72.2% 43.9%, 72.2% 90%, 73.8% 90%, 74.2% 83%, 76.9% 86%, 78.6% 43.9%);
}
.imageHoverTable .image-area .hover-area-7-1 {
  clip-path: polygon(34.8% 0.5%, 44.8% 0.5%, 44.8% 20.1%, 39.8% 20.1%, 39.8% 30.6%, 34.8% 30.6%);
}
.imageHoverTable .image-area .hover-area-7-2 {
  clip-path: polygon(60% 1.9%, 69.2% 1.9%, 69.2% 20.1%, 65.3% 20.1%, 65.3% 30.6%, 60% 30.6%);
}
.imageHoverTable .table-area h4 {
  margin-bottom: 24px;
}
.imageHoverTable .mobile-table-link {
  display: none;
}
.imageHoverTable .open-table-link.hidden {
  display: none;
}
.imageHoverTable .table-box {
  margin-bottom: 40px;
}
.imageHoverTable .hover-table {
  transition: all ease 0.3s;
  border-color: #d2d2d2;
  background-color: rgba(126, 186, 215, 0);
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dfdfdf;
  padding: 6px 0;
  cursor: pointer;
}
.imageHoverTable .hover-table:last-child {
  border-bottom: 1px solid #dfdfdf;
}
.imageHoverTable .hover-table.hover,
.imageHoverTable .hover-table:hover {
  background-color: rgba(126, 186, 215, 0.5);
}
.imageHoverTable .hover-row {
  width: 12%;
  padding: 0 5px;
  text-align: center;
}
.imageHoverTable .hover-row.detail-row {
  width: 28%;
}
.imageHoverTable .hover-row:first-child {
  padding-left: 0;
  text-align: left;
}
.imageHoverTable .hover-row:last-child {
  padding-right: 0;
  text-align: right;
}
.imageHoverTable .HoverPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 999;
  opacity: 0;
  transition: opacity ease 0.3s, visibility ease 0s 0.3s;
  padding: 0;
  width: auto;
  visibility: hidden;
}
.imageHoverTable .HoverPopup.open {
  visibility: visible;
  opacity: 1;
  transition: opacity ease 0.3s;
}
.imageHoverTable .HoverPopup .close-btn {
  position: fixed;
  top: 28px;
  right: 28px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.imageHoverTable .HoverPopup .close-btn:before,
.imageHoverTable .HoverPopup .close-btn:after {
  content: "";
;
  display: block;
  width: 20px;
  height: 1px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
}
.imageHoverTable .HoverPopup .close-btn:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.imageHoverTable .HoverPopup .close-btn:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.imageHoverTable .pop-cont {
  width: 90vw;
  max-width: 100%;
  height: 900px;
  max-height: calc(100vh - 160px);
  overflow: auto;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.imageHoverTable .pop-cont h3 {
  margin: 0;
  font-size: 31px;
  padding-right: 40px;
  flex-grow: 0;
  flex-shrink: 0;
}
.imageHoverTable .pop-image {
  margin: 67px 0 0;
  text-align: center;
  flex-grow: 1;
  flex-shrink: 1;
  height: 300px;
}
.imageHoverTable .pop-image img {
  min-height: 300px;
  max-width: 100%;
  height: 100% !important;
}
.imageHoverTable .pop-btns {
  margin: 67px 0 0;
  flex-grow: 0;
  flex-shrink: 0;
}
.imageHoverTable .pop-btns > a {
  background-color: #000;
  color: #fff;
  padding: 10px 20px 13px;
  border: 2px solid #000;
  border-radius: 8px;
  margin-right: 40px;
  transition: all 0.3s ease;
}
.imageHoverTable .pop-btns > a:after {
  display: none;
}
.imageHoverTable .pop-btns > a:hover,
.imageHoverTable .pop-btns > a:focus {
  background-color: #fff;
  color: #000;
}
.imageHoverTable .bg-change {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity ease 0.3s, visibility 0s 0.3s;
}
.imageHoverTable .bg-change.open {
  visibility: visible;
  opacity: 1;
  transition: opacity ease 0.3s;
}
@media screen and (max-width: 1024px) {
  .imageHoverTable .image-area {
    display: none;
  }
  .imageHoverTable .table-area {
    margin-top: 67px;
  }
  .imageHoverTable .mobile-table-link {
    display: inline-block;
    margin: 10px 0 40px;
    padding: 0;
    background-color: transparent;
  }
  .imageHoverTable .mobile-table-link:after {
    transform: none;
    margin-top: 0.4em;
  }
  .imageHoverTable .table-box {
    margin-bottom: 0;
  }
  .imageHoverTable .hover-table {
    flex-wrap: wrap;
  }
  .imageHoverTable .hover-table:nth-child(n+3) {
    height: 0;
    overflow: hidden;
    padding: 0;
    border: none;
  }
  .imageHoverTable .hover-table:last-child {
    border-bottom: 1px solid #d2d2d2;
  }
  .imageHoverTable .hover-table a {
    display: inline-flex;
  }
  .imageHoverTable .hover-table a:after {
    margin-top: 0.1em;
  }
  .imageHoverTable .hover-row {
    width: 100%;
    padding: 3px 0;
    text-align: left;
  }
  .imageHoverTable .hover-row.detail-row {
    width: 100%;
  }
  .imageHoverTable .hover-row:last-child {
    text-align: left;
  }
  .imageHoverTable .table-box.open-table {
    margin-bottom: 40px;
  }
  .imageHoverTable .hover-table:nth-child(n+3) {
    height: auto;
    border-top: 1px solid #dfdfdf;
    padding: 6px 0;
  }
  .imageHoverTable .close-btn {
    position: fixed;
    top: 12px;
    right: 12px;
  }
  .imageHoverTable .pop-cont {
    padding: 24px;
    max-height: calc(100vh - 80px);
  }
  .imageHoverTable .pop-btns {
    margin: 16px 0 0;
  }
  .imageHoverTable .pop-btns > a {
    display: inline-block;
    margin: 24px 24px 0 0;
  }
  .imageHoverTable .pop-image {
    margin-top: 40px;
    height: 200px;
  }
  .imageHoverTable .pop-image > img {
    min-height: 200px;
  }
}
