@media (min-width: 768px) and (max-width: 991px) {
    .usp:nth-child(2n) {
        flex-direction: column-reverse;
    }
}

@media (min-width: 992px) {
    .usp:nth-child(3n+2) {
        flex-direction: column-reverse;
    }
}

.usp-icon {
    height: 98px;
}

.usp-icon img {
    max-width: 98px;
    max-height: 98px;
}

.usp-title + .caption {
    margin-top: 28px;
}

