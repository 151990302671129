.snap-slider {
  width: 100vw;
  margin: 0 calc( (100vw - var(--page-maxwidth)) / -2);
}

@media (min-width: 1140px) and (max-width: 1440px) {
  .snap-slider {
    width: calc(100% + 2 * var(--page-gutter-width));
    margin: 0 calc( var(--page-gutter-width) * -1 );
  }
}

@media (min-width: 768px) and (max-width: 1139px) {
  .snap-slider {
    width: calc(100% + 2 * var(--page-gutter-width));
    margin: 0 calc( var(--page-gutter-width) * -1 );
  }
}

@media (max-width: 767px) {
  .snap-slider {
    width: calc(100% + 2 * var(--page-gutter-width));
    margin: 0 calc( var(--page-gutter-width) * -1 );
  }
}

.stripe {
  margin-bottom: -20px;
  background-color: inherit;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.stripe::-webkit-scrollbar {
  display: none;
}

snap-slider,
.stripe {
  position: relative;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  padding-bottom: 24px;
  overflow-y: hidden;
}

.stripe > * {
  scroll-snap-align: center;
  min-height: 200px;
  height: 34vw;
}

.stripe img {
  height: 640px;
  border-right: 16px solid transparent;
  box-sizing: border-box;
}

.stripe img.placeholder {
  opacity: 0;
  visibility: hidden;
  max-width: none;
}

.snap-slider-arrows {
  position: absolute;
  top: 100%;
  left: 0;
  right: -15px;
}

.arrow {
  bottom: auto;
  left: 0;
  outline: 0;
  opacity: 0.25;
  transition: visibility 0s linear 0.25s, opacity 0.15s linear;
  width: 48px;
  height: 48px;
}

.arrow path {
  fill: none;
  stroke: black;
  stroke-width: 5;
  stroke-miterlimit: 10;
}

.arrow.prev::after,
.arrow.next::after {
  content: "\e628";
  display: inline-block;
  font-family: 'themify', sans-serif;
  vertical-align: middle;
  font-size: 1em;
  font-weight: 600;
}

.arrow.prev::after {
  content: "\e629";
}

.arrow.js-active {
  display: inline-block;
}

.arrow.js-show {
  opacity: 1;
  transition: opacity 0.15s linear 0.15s;
}

@media (min-width: 768px) {
  .stripe > *, .stripe img {
    height: 624px;
  }

  .arrow {
    top: 390px;
  }
}

@media (max-width: 992px) {
  .stripe > *, .stripe img {
    height: 400px;
  }
}

@media (max-width: 767px) {

  .stripe > * {
    height: 182px;
    min-height: 182px;
  }

  .stripe img {
    height: 182px;
    border-right: 20px solid transparent;
  }

  snap-slider,
  .stripe {
    padding-bottom: 0;
  }
}
