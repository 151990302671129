.o-testimonials .h4,
.o-testimonials-highlight .h4,
.o-testimonials-text .h4 {
    letter-spacing: 0;
}

.o-testimonials .stars {
    width: 104px;
    margin-bottom: 32px;
    margin-left: 2px;
}

.o-testimonials .caption {
    margin-top: 32px;
}

.o-testimonials img {
    max-height: 345px;
    object-fit: cover;
}

.o-testimonials-highlight .stars {
    width: 104px;
    margin-top: 2px;
    margin-left: 2px;
    margin-bottom: 29px;
}

@media (min-width: 768px) {
    .o-testimonials-highlight img {
        min-height: 600px;
        object-fit: cover;
    }
}

.o-testimonials-highlight .caption {
    margin-top: 29px;
}

.o-testimonials-text .stars {
    width: 104px;
    margin-left: 3px;
    margin-bottom: 28px;
}

.o-testimonials-text .caption {
    margin-top: 28px;
}

@media (max-width: 768px) {
    .o-testimonials .o-flex-middle {
        flex-flow: column-reverse;
    }

    .o-testimonials .stars {
        margin-bottom: 30px;
    }

    .o-testimonials .caption {
        margin-top: 28px;
    }

    .o-testimonials img {
        min-height: 230px;
    }

    .o-testimonials-text .caption {
        margin-top: 30px;
        margin-bottom: 5px;
    }
}
