ul.benefit-list {
  padding-inline-start: 0;
}
@media (min-width: 768px) {
  .benefit-list.has-2-cols {
    columns: 2;
    column-gap: var(--layout-block-gap)
  }

  .benefit-list.has-3-cols {
    columns: 3 auto;
    break-inside: avoid-column;
    column-gap: var(--layout-tiny-gap)
  }
}

.benefit-item {
  position: relative;
  list-style: none;
  break-inside: avoid-column;
  border-top: 1px solid var(--grau2);
  margin-bottom: 0;
  padding: 8px 0 10px 34px;
  min-height: 43px;
  color: var(--schwarz);
  display: block;
}

.benefit-item.benefit-placeholder {
  opacity: 0;
}

@media (max-width: 768px) {
  .benefit-item.benefit-placeholder {
    display: none;
  }
}

.benefit-item:not(.available) {
  color: var(--grau1);
}

.benefit-item > svg {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 3px;
  top: 13px;
}
