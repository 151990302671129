.segment-tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  font-size: 16px;
  padding-top: 5px;
  padding-left: 0;
  margin-bottom: -20px;
}

.segment-tab {
  margin-bottom: 20px;
}

.segment-tab:not(:last-child) {
  margin-right: 12px;
}

.segment-tab-link {
  border-radius: 8px;
  white-space: nowrap;
  padding: 6px 8px;
  border: 1px solid var(--off-white);
}

.segment-tab-link:hover,
.segment-tab-link.js-active {
  background-color: var(--off-white);
  color: var(--schwarz);
}

.segment-pane:not(.js-active) {
  display: none;
}

.segment-pane.js-active {
  display: block;
}

@media (max-width: 768px) {
  .segment-tabs {
    padding-top: 6px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
